<template>
    <el-container>
        <el-main>
            <el-row :gutter="20" style="height: 100%;">
                <el-col :span="12">
                    <el-aside class="aside">
                        <el-container>
                            <el-header>
                                <el-input placeholder="输入关键字进行过滤" v-model="orgFilterText" clearable></el-input>
                            </el-header>
                            <el-main class="nopadding">
                                <el-tree ref="org" class="menu" node-key="Id" :data="orgList" :props="orgProps" draggable highlight-current :expand-on-click-node="false" :check-strictly="true" show-checkbox :filter-node-method="orgFilterNode" @check-change="handleCheckChange" @node-click="orgClick">
                                    <template #default="{node}">
                                        <span class="custom-tree-node">
                                            <span class="label"> {{ node.label }}</span>
                                        </span>
                                    </template>
                                </el-tree>
                            </el-main>
                        </el-container>
                    </el-aside>
                </el-col>
                <el-col :span="12">
                    <el-aside class="aside">
                        <el-container>
                            <el-header>
                                <el-input placeholder="输入关键字进行过滤" v-model="menuFilterText" clearable></el-input>
                            </el-header>
                            <el-main class="nopadding">
                                <el-tree ref="menu" class="menu" @check="nodeCheck" :default-checked-keys="checkedkeys" node-key="Id" :data="menuList" :props="menuProps" draggable highlight-current :expand-on-click-node="false" :check-strictly="checkstrictly" show-checkbox :filter-node-method="menuFilterNode" @node-click="menuClick">
                                    <template #default="{node, data}">
                                        <span class="custom-tree-node">
                                            <span class="label"><i :class="data.MenuPCIcon" style="padding-right:5px"></i> {{ node.label }}</span>
                                            <span class="do">
                                                <i class="el-icon-plus" @click.stop="add(node, data)"></i>
                                            </span>
                                        </span>
                                    </template>
                                </el-tree>
                            </el-main>
                        </el-container>
                    </el-aside>
                </el-col>
            </el-row>
        </el-main>
        <el-footer>
            <div style="width:100%;text-align:center">
                <el-button type="primary" @click="submit" :loading="isSubmit">确认提交</el-button>
            </div>
        </el-footer>
    </el-container>
</template>

<script>
export default {
    name: "orgauthor",
    data() {
        return {
            menuList: [],
            orgList: [],
            checkstrictly: false,
            checkedkeys: [],
            menuProps: {
                label: (data) => {
                    return data.MenuName;
                },
                children: (data) => {
                    return data.Children;
                },
            },
            orgProps: {
                label: (data) => {
                    return data.OrgName;
                },
                children: (data) => {
                    return data.Children;
                },
            },
            menuFilterText: "",
            orgFilterText: "",
            isSubmit: false,
        };
    },
    watch: {
        menuFilterText(val) {
            this.$refs.menu.filter(val);
        },
        orgFilterText(val) {
            this.$refs.org.filter(val);
        },
    },
    mounted() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.$API.Menu.GetMenuList.get().then((res) => {
                this.menuList = res;
            });
            this.$API.Org.GetOrgList.get().then((res) => {
                this.orgList = res;
            });
        },
        //树过滤
        menuFilterNode(value, data) {
            if (!value) return true;
            var targetText = data.MenuName;
            return targetText.indexOf(value) !== -1;
        },
        //树过滤
        orgFilterNode(value, data) {
            if (!value) return true;
            var targetText = data.OrgName;
            return targetText.indexOf(value) !== -1;
        },
        //树点击
        menuClick(data, node) {
            console.log(data);
            console.log(node);
        },
        nodeCheck() {
            //this.checkstrictly = false;
        },
        //树点击
        orgClick(data, node) {
            if (!node.checked) {
                this.handleCheckChange(data, true);
            }
        },
        //组织树单选
        handleCheckChange(data, checked) {
            if (checked) {
                this.$refs.org.setCheckedKeys([data.Id]);
                //获取用户权限
                this.$API.OrgAuthority.QueryByOrgId.get(data.Id).then((res) => {
                    //
                    var that = this;
                    that.$refs.menu.setCheckedNodes([]);
                    res.forEach((element) => {
                        that.$refs.menu.setChecked(element.MenuId, true, false);
                    });
                });
            }
        },
        submit() {
            var orgId = this.$refs.org.getCheckedKeys();
            var menuIds = this.$refs.menu.getCheckedKeys();
            menuIds.push.apply(menuIds, this.$refs.menu.getHalfCheckedKeys());
            console.log(orgId);
            console.log(menuIds);
            var addListOrgAuthority = {
                OrgId: orgId[0],
                MenuIds: menuIds,
            };
            this.$API.OrgAuthority.AddOrgAuthor.post(addListOrgAuthority).then(
                (res) => {
                    console.log(res);
                    this.$notify({
                        title: "成功",
                        message: "操作成功！",
                        type: "success",
                    });
                }
            );
        },
    },
};
</script>

<style scoped>
.el-main {
    padding: 0px;
}
.custom-tree-node {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 24px;
    height: 100%;
}
.custom-tree-node .label {
    display: flex;
    align-items: center;
    height: 100%;
}
.custom-tree-node .label .el-tag {
    margin-left: 5px;
}
.custom-tree-node .do {
    display: none;
}
.custom-tree-node .do i {
    margin-left: 5px;
    color: #999;
    padding: 5px;
}
.custom-tree-node .do i:hover {
    color: #333;
}

.custom-tree-node:hover .do {
    display: inline-block;
}
.icon {
    padding-right: 10px;
}
.aside {
    display: inline-block;
    height: 100%;
    width: 100%;
}
</style>